var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"operating-w"},[_vm._m(0),_c('div',{staticClass:"main-w"},[_vm._m(1),_c('div',{staticClass:"carouselBox"},[_c('el-carousel',{staticClass:"carousel",attrs:{"loop":false,"autoplay":false}},_vm._l((_vm.dataList),function(list,index){return _c('el-carousel-item',{key:index,staticClass:"el-car-item"},_vm._l((list),function(imgList,index1){return _c('div',{key:index1,staticClass:"divSrc"},[_c('img',{staticClass:"img",attrs:{"src":imgList.img}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(imgList.title))])])}),0)}),1)],1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-w"},[_c('img',{staticClass:"banner-w_img",attrs:{"src":require("@/assets/image/yypt_bg.png"),"alt":""}}),_c('div',{staticClass:"banner-w_top"},[_c('div',{staticClass:"banner-w_top_l"},[_c('div',{staticClass:"banner-w_top_l_t"},[_vm._v("医养管家APP")]),_c('div',{staticClass:"banner-w_top_l_p"},[_vm._v("医养管家监护管理平台")]),_c('div',{staticClass:"banner-w_top_l_h"},[_vm._v("百年医养管家可通过医养管家APP关联用户，实时查看用户健康趋势数据、管理用户健康状态、统计分析高风险用户，并提供健康指导等服务内容。 医养管家可在平台上管理自己的下线管家，也可给用户分享对应的优质服务和商品给有需求的用户。")])]),_c('div',{staticClass:"banner-w_top_r"},[_c('img',{staticClass:"banner-w_imgs",attrs:{"src":require("@/assets/image/mCare_d_p1.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-w_top"},[_c('div',{staticClass:"main-w-img-r left",staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('img',{staticClass:"yypt_icon",attrs:{"src":require("@/assets/image/yypt_icon.png"),"alt":""}})]),_c('div',{staticClass:"main-w_top_p"},[_vm._v("主要服务")]),_c('div',{staticClass:"main-w_top_h"},[_vm._v("用户健康监护、健康周报分析、上门护理服务、陪诊、中医理疗、推拿按摩")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-w-img"},[_c('div',{staticClass:"main-w-img-r bg"},[_c('div',{staticStyle:{"position":"absolute","top":"38%","right":"26%"}},[_c('img',{staticClass:"yypt_icon",attrs:{"src":require("@/assets/image/yypt_icon.png"),"alt":""}}),_c('div',{staticClass:"main-w-img-r-c"},[_c('div',{staticClass:"main-w-img-r-c-t"},[_vm._v("用户管理")]),_c('div',{staticClass:"main-w-img-r-c-p"},[_vm._v("医养管家能够实时查看附近用户的健康状态，实现对用户健康状态的全程了解、知悉。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-w-img-right"},[_c('div',{staticClass:"main-w-img-right-r bg"},[_c('div',{staticStyle:{"position":"absolute","top":"15%","left":"3%"}},[_c('img',{staticClass:"yypt_icon",attrs:{"src":require("@/assets/image/yypt_icon.png"),"alt":""}}),_c('div',{staticClass:"main-w-img-right-r-c"},[_c('div',{staticClass:"main-w-img-right-r-c-t"},[_vm._v("查看实时状态")]),_c('div',{staticClass:"main-w-img-right-r-c-p"},[_vm._v("医养管家通过关注附近用户，可进入用户主页面查看用户实时健康状态，如心率、血压、血氧等基本趋势监测数据，及睡眠等健康状态，并及时给予异常用户健康提醒。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-w-img"},[_c('div',{staticClass:"main-w-img-r bg1"},[_c('div',{staticStyle:{"position":"absolute","top":"32%","right":"26%"}},[_c('img',{staticClass:"yypt_icon",attrs:{"src":require("@/assets/image/yypt_icon.png"),"alt":""}}),_c('div',{staticClass:"main-w-img-r-c"},[_c('div',{staticClass:"main-w-img-r-c-t"},[_vm._v("用户详情分析")]),_c('div',{staticClass:"main-w-img-r-c-p t1"},[_c('div',[_vm._v("一个医养管家可同时管理多名用户，并通过对每位用户")]),_c('div',[_vm._v("的实时趋势数据、历史趋势数据对比，实现对用户数数")]),_c('div',[_vm._v("数量及高危风险用户的数据统计。")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-w-img-right"},[_c('div',{staticClass:"main-w-img-right-r bg1"},[_c('div',{staticStyle:{"position":"absolute","top":"18%","left":"0%"}},[_c('img',{staticClass:"yypt_icon",attrs:{"src":require("@/assets/image/yypt_icon.png"),"alt":""}}),_c('div',{staticClass:"main-w-img-right-r-c"},[_c('div',{staticClass:"main-w-img-right-r-c-t"},[_vm._v("优质资讯定向推送")]),_c('div',{staticClass:"main-w-img-right-r-c-p"},[_vm._v("医养管家可以把图文、产品和服务通过平台的消息功能、微信好友，朋友圈分享给自己的客户。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-w-img"},[_c('div',{staticClass:"main-w-img-r bg2"},[_c('div',{staticStyle:{"position":"absolute","top":"29%","right":"26%"}},[_c('img',{staticClass:"yypt_icon",attrs:{"src":require("@/assets/image/yypt_icon.png"),"alt":""}}),_c('div',{staticClass:"main-w-img-r-c"},[_c('div',{staticClass:"main-w-img-r-c-t"},[_vm._v("管理下级医养管家")]),_c('div',{staticClass:"main-w-img-r-c-p"},[_vm._v("医养管家可以搜索和查看下级管家的数据，包括关联用户数量、上月新关联用户量、图文分享次数、成交金额等信息。 ")])])])])])
}]

export { render, staticRenderFns }